// extracted by mini-css-extract-plugin
export var additionalInfo = "PerkCard-module--additionalInfo--7a793";
export var alertRed = "PerkCard-module--alert-red--53a5a";
export var arrow = "PerkCard-module--arrow--1fcdd";
export var arrowDown = "PerkCard-module--arrowDown--6749c";
export var bgBarGrey = "PerkCard-module--bg-bar-grey--f33c1";
export var bgBeige = "PerkCard-module--bg-beige--fb8fa";
export var bgBeige2 = "PerkCard-module--bg-beige2--b3982";
export var bgCopper = "PerkCard-module--bg-copper--d0d72";
export var bgDaffodil = "PerkCard-module--bg-daffodil--76b93";
export var bgGreyText = "PerkCard-module--bg-grey-text--0a048";
export var bgMing = "PerkCard-module--bg-ming--75a35";
export var bgMint = "PerkCard-module--bg-mint--ed8fc";
export var bgRed = "PerkCard-module--bg-red--5a542";
export var bgRedTrans = "PerkCard-module--bg-red-trans--a81ee";
export var bgSand = "PerkCard-module--bg-sand--e107d";
export var bgSoftSun = "PerkCard-module--bg-softSun--4f513";
export var bgTeal = "PerkCard-module--bg-teal--d2eed";
export var bgWhite = "PerkCard-module--bg-white--06c4c";
export var bgWomenSharp = "PerkCard-module--bg-women-sharp--6cc18";
export var blackText = "PerkCard-module--black-text--66a3b";
export var borderMing = "PerkCard-module--border-ming--4f468";
export var bottomText = "PerkCard-module--bottomText--d4c43";
export var content = "PerkCard-module--content--b5c9e";
export var copper = "PerkCard-module--copper--9f6e2";
export var corners = "PerkCard-module--corners--4182d";
export var cursorNormal = "PerkCard-module--cursor-normal--c7f9e";
export var cursorPointer = "PerkCard-module--cursor-pointer--b1c54";
export var customMarkdown = "PerkCard-module--customMarkdown--9b5c5";
export var dUnset = "PerkCard-module--d-unset--d47fc";
export var darkGrey = "PerkCard-module--dark-grey--165d9";
export var dash = "PerkCard-module--dash--9ecca";
export var dashBig = "PerkCard-module--dashBig--46f56";
export var deadSalmon = "PerkCard-module--dead-salmon--74cde";
export var flex = "PerkCard-module--flex--7606b";
export var flex1 = "PerkCard-module--flex-1--47898";
export var fontSize12 = "PerkCard-module--font-size-12--3e3c3";
export var fontSize20 = "PerkCard-module--font-size-20--4c5db";
export var greyPlaceholder = "PerkCard-module--grey-placeholder--fd899";
export var greyText = "PerkCard-module--grey-text--5113a";
export var h1sizing = "PerkCard-module--h1sizing--ec00f";
export var h2sizing = "PerkCard-module--h2sizing--4931f";
export var hidden = "PerkCard-module--hidden--6e2e9";
export var image = "PerkCard-module--image--39f48";
export var imageAni = "PerkCard-module--imageAni--5bcd8";
export var imageWrapper = "PerkCard-module--imageWrapper--9669e";
export var imageZoom = "PerkCard-module--imageZoom--9b794";
export var imageZoomGatsby = "PerkCard-module--imageZoomGatsby--63de7";
export var italic = "PerkCard-module--italic--d69c0";
export var lightGrey = "PerkCard-module--light-grey--af541";
export var logo = "PerkCard-module--logo--cce89";
export var lora = "PerkCard-module--lora--db402";
export var loraBold = "PerkCard-module--lora-Bold--5cbb7";
export var loraBoldItalic = "PerkCard-module--lora-BoldItalic--3151f";
export var loraMedium = "PerkCard-module--lora-Medium--79410";
export var loraSemiBold = "PerkCard-module--lora-SemiBold--3d1eb";
export var main = "PerkCard-module--main--61edb";
export var ming = "PerkCard-module--ming--ef7b8";
export var mingHover2 = "PerkCard-module--ming-hover-2--c9f29";
export var modalOpen = "PerkCard-module--modal-open--c86a7";
export var noUnderline = "PerkCard-module--no-underline--116c1";
export var openSans = "PerkCard-module--openSans--5f57a";
export var openSansBold = "PerkCard-module--openSans-Bold--ee682";
export var openSansSemiBold = "PerkCard-module--openSans-SemiBold--dd682";
export var opening = "PerkCard-module--opening--323e6";
export var pageGuide = "PerkCard-module--page-guide--2e805";
export var perkCard = "PerkCard-module--perkCard--bb4b6";
export var popUp = "PerkCard-module--popUp--9dd83";
export var popUpAni = "PerkCard-module--pop-up-ani--288be";
export var quotation = "PerkCard-module--quotation--a2643";
export var round = "PerkCard-module--round--4bd0d";
export var roundCorners = "PerkCard-module--round-corners--fafdb";
export var softCorners = "PerkCard-module--soft-corners--5f76b";
export var softShadows = "PerkCard-module--soft-shadows--c08d5";
export var softerCorners = "PerkCard-module--softer-corners--bc51e";
export var softyCorners = "PerkCard-module--softy-corners--7f54b";
export var tableShadow = "PerkCard-module--table-shadow--faf39";
export var teal = "PerkCard-module--teal--ab681";
export var title = "PerkCard-module--title--9d98c";
export var topNavShadow = "PerkCard-module--top-nav-shadow--e75e5";
export var topicArea = "PerkCard-module--topicArea--f70d1";
export var uppercase = "PerkCard-module--uppercase--44271";
export var wFull = "PerkCard-module--w-full--75ac8";
export var white = "PerkCard-module--white--cb1fb";
export var womenRed = "PerkCard-module--women-red--cc96c";
export var womenSharp = "PerkCard-module--women-sharp--77d6a";